import { InfoWindow } from '@react-google-maps/api';
import Modal from '../Modal';
import LocationTooltipContent from './partials/LocationTooltipContent';
import { LocationTooltipProps } from './types';
const LocationTooltip = ({
  location,
  isBuyAndSell,
  onClose,
  availableCoins,
  position,
  openedTooltipId,
  isMobile
}: LocationTooltipProps) => {
  return isMobile ? <Modal isOpen={openedTooltipId === location.id} contentClassName="w-auto p-12">
      <div className="absolute left-1/2 top-1/2 z-50 w-max max-w-[calc(100vw-4rem)] -translate-x-1/2 -translate-y-1/2 rounded-[0.625rem] bg-white p-4 lg:max-w-[20.3125rem] lg:p-6">
        <LocationTooltipContent {...{
        location,
        availableCoins,
        isBuyAndSell,
        onClose
      }} />
      </div>
    </Modal> : <InfoWindow onCloseClick={onClose} position={position} options={{
    headerDisabled: true,
    pixelOffset: new google.maps.Size(0, -8),
    ariaLabel: 'Location tooltip'
  }}>
      <div className="z-50 w-max max-w-[calc(100vw-4rem)] rounded-[0.625rem] bg-white p-4 lg:max-w-[20.3125rem] lg:p-6">
        <LocationTooltipContent {...{
        location,
        availableCoins,
        isBuyAndSell,
        onClose
      }} />
      </div>
    </InfoWindow>;
};
export default LocationTooltip;