import { ListItemKeySelector } from 'react-window';

import { Location } from '@/utils/types';

import { DESKTOP_HEIGHT, ITEM_SIZE, MOBILE_HEIGHT } from './consts';

export const getItemKey: ListItemKeySelector<Location[]> = (index, data) => data[index].id;

export const getListHeight = (items: Location[], isDesktop: boolean) => {
  const defaultHeight = isDesktop ? DESKTOP_HEIGHT : MOBILE_HEIGHT;

  return Math.min(items.length * ITEM_SIZE, defaultHeight);
};
