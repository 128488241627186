'use client';

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import useSWR from 'swr';
import { GroupField } from '@prismicio/client';
import { useSearchParams } from 'next/navigation';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { mapStyles } from '@/utils';
import Tab from '@/components/atoms/Tab';
import LocationInput from '@/components/molecules/LocationInput';
import LocationList from '@/components/molecules/LocationList';
import MapMarker from '@/components/molecules/MapMarker';
import SearchModal from '@/components/molecules/SearchModal';
import LinkButton from '@/components/atoms/LinkButton';
import { CoinsDocumentDataCoinsItem, Simplify } from '@/prismicio-types';
import Sort from '@/public/assets/svg/sort.svg';
import useRWD from '@/hooks/useRWD';
import useUserLocation from '@/hooks/useUserLocation';
import { useLang } from '@/hooks/useLang';
import { ROUTES } from '@/routes';
import { getLocations } from '@/utils/swrRequests';
import { defaultMapFilters } from '@/utils/consts';
import { BrowserLocation, Location, MapFiltersType, ServiceType } from '@/utils/types';
import { useLoadGoogleMapsScript } from '@/hooks/useLoadGoogleMapsScript';
import { LOCALES } from '@/i18nConfig';
import { pushGTMEventForATM } from '@/utils/gtm';
import { ATM_MAP_MARKER_CLICK_EVENT } from '@/utils/gtm/consts';
import { getInitialZoom } from '@/utils/maps';
import { useInitialMapCenter } from './hooks';
import { getAvailableCoins } from './utils';
import { clusterStyles, SERVICE_TYPES } from './consts';
import MapLegend from './partials/MapLegend';
type Props = {
  coins: GroupField<Simplify<CoinsDocumentDataCoinsItem>>;
  defaultLocation?: BrowserLocation;
  reduceContainer?: boolean;
  noList?: boolean;
  className?: string;
  mapContainerClassName?: string;
  locationInputClassName?: string;
  zoom?: number;
};
const Map = ({
  coins,
  defaultLocation: defaultLocationProp,
  reduceContainer,
  noList,
  className,
  mapContainerClassName,
  locationInputClassName,
  zoom: zoomProp
}: Props) => {
  const lang = useLang();
  const {
    isDesktop,
    isMobile
  } = useRWD();
  const initialZoom = zoomProp ? zoomProp : getInitialZoom(lang);
  const [map, setMap] = useState<google.maps.Map>();
  const [openModal, setOpenModal] = useState(false);
  const [isMapActive, setIsMapActive] = useState(true);
  const [tooltipLocationId, setTooltipLocationId] = useState('');

  // State for storing current map bounds (for list filtering only)
  const [currentBounds, setCurrentBounds] = useState<google.maps.LatLngBounds | null>(null);
  const searchParams = useSearchParams();
  const longitude = searchParams.get('longitude');
  const latitude = searchParams.get('latitude');
  const placeId = searchParams.get('placeId');
  const filters = useMemo<MapFiltersType>(() => {
    const openNow = searchParams.get('openNow') === 'true';
    const serviceType = searchParams.get('serviceType') || defaultMapFilters.serviceType;
    return {
      openNow,
      serviceType: SERVICE_TYPES.includes(serviceType as ServiceType) ? serviceType as ServiceType : 'all'
    };
  }, [searchParams]);
  const {
    t
  } = useTranslation();
  const defaultLocationFromParams = useMemo(() => longitude && latitude ? {
    longitude: +longitude,
    latitude: +latitude
  } : undefined, [latitude, longitude]);
  const defaultLocation = defaultLocationProp || defaultLocationFromParams;
  useUserLocation({
    enabled: !!map && !defaultLocation,
    onSuccess: data => {
      const location = data?.location;
      return map?.setCenter(new google.maps.LatLng({
        lat: location.latitude,
        lng: location.longitude
      }));
    }
  });
  const {
    data: allLocations,
    isLoading
  } = useSWR([ROUTES.APILocations, lang], async () => await getLocations(lang));
  const {
    isLoaded: isMapLoaded
  } = useLoadGoogleMapsScript();
  const initialMapCenter = useInitialMapCenter(defaultLocation, isMapLoaded);
  const [centerPosition, setCenterPosition] = useState<google.maps.LatLng | google.maps.LatLngLiteral | undefined>(initialMapCenter);
  const updateBounds = useCallback(() => {
    if (!map) return;
    const bounds = map.getBounds();
    if (bounds) {
      setCurrentBounds(bounds);
    }
  }, [map]);

  // Debounced version of the update function
  const updateMapBounds = useMemo(() => debounce(() => {
    updateBounds();
  }, 300), [updateBounds]);
  const handleApplyFilters = useCallback((newFilters: MapFiltersType) => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('openNow', newFilters.openNow.toString());
    params.set('serviceType', newFilters.serviceType);
    setOpenModal(false);
    window.history.replaceState(null, '', `?${params.toString()}`);
  }, [searchParams]);
  const onChangeLocation = useCallback(({
    location,
    id
  }: Location) => {
    if (map) {
      map.setZoom(16);
      map.setCenter(new google.maps.LatLng({
        lat: +location.latitude,
        lng: +location.longitude
      }));
    }
    setIsMapActive(true);
    setTooltipLocationId(id);
  }, [map]);

  // Filter all markers by service type
  const allFilteredLocations = useMemo(() => {
    if (!allLocations?.result) return [];
    return allLocations.result.filter(location => {
      if (filters.serviceType === 'buy-and-sell' && !location.isTwoWay) {
        return false;
      }
      return true;
    });
  }, [allLocations?.result, filters]);

  // Filter locations for list display based on map bounds
  const listLocations = useMemo(() => {
    if (!currentBounds) return allFilteredLocations;

    // First filter by map bounds
    const locationsInBounds = allFilteredLocations.filter(location => {
      return currentBounds.contains({
        lat: +location.location.latitude,
        lng: +location.location.longitude
      });
    });

    // Then sort by distance from map center
    if (map && map.getCenter()) {
      const mapCenter = map.getCenter();
      if (mapCenter) {
        return locationsInBounds.sort((a, b) => {
          const aLat = +a.location.latitude;
          const aLng = +a.location.longitude;
          const bLat = +b.location.latitude;
          const bLng = +b.location.longitude;

          // Calculate squared distance (faster than using Math.sqrt)
          const aDist = Math.pow(aLat - mapCenter.lat(), 2) + Math.pow(aLng - mapCenter.lng(), 2);
          const bDist = Math.pow(bLat - mapCenter.lat(), 2) + Math.pow(bLng - mapCenter.lng(), 2);
          return aDist - bDist; // Sort from closest to farthest
        });
      }
    }
    return locationsInBounds;
  }, [allFilteredLocations, currentBounds, map]);
  const onMarkerClick = useCallback((event: google.maps.MapMouseEvent, item: Location) => {
    if (!event.latLng) {
      return;
    }
    setTooltipLocationId(item.id);
    pushGTMEventForATM(item, ATM_MAP_MARKER_CLICK_EVENT);
  }, []);
  const onMarkerClose = useCallback(() => {
    setTooltipLocationId('');
  }, []);

  // Initialize map bounds after loading
  useEffect(() => {
    if (map) {
      updateBounds();
      return () => {
        // Clean up debounced function when component unmounts
        updateMapBounds.cancel();
      };
    }
  }, [map, updateBounds, updateMapBounds]);
  return <div className={clsx('mt-3 gap-x-5 lg:min-h-[40.9375rem] lg:grid-cols-[28.75rem_1fr]', reduceContainer && '-mx-6', noList ? 'block' : 'lg:grid', className)} data-sentry-component="Map" data-sentry-source-file="Map.tsx">
      <div className="flex w-full flex-col">
        <div className={clsx('items-center justify-center gap-x-2 px-6 lg:flex lg:px-0', locationInputClassName)}>
          <LocationInput className="mb-3 h-11 w-full lg:mb-5 lg:w-2/3" onSelect={coords => {
          if (typeof coords.latitude !== 'number' || typeof coords.longitude !== 'number') {
            return;
          }
          const center = new google.maps.LatLng({
            lat: coords.latitude,
            lng: coords.longitude
          });
          const zoom = getInitialZoom(lang);
          map?.setZoom(zoom);
          map?.setCenter(center);
          setCenterPosition(center);
          setTooltipLocationId('');
        }} defaultValue={placeId} isGoogleMapsScriptLoaded={isMapLoaded} placeholder={t('map.inputPlaceholder')} loadingText={t('common.loadingText')} noOptionsMessage={t('common.noOptionsText')} data-sentry-element="LocationInput" data-sentry-source-file="Map.tsx" />
          <button onClick={() => setOpenModal(true)} className="mb-7 flex h-11 w-full items-center justify-center gap-x-2 rounded border border-gray-200 bg-white lg:mb-5 lg:w-1/3">
            <Sort data-sentry-element="Sort" data-sentry-source-file="Map.tsx" /> {t('map.filtersModal.triggerButtonLabel')}
          </button>
        </div>
        <SearchModal isOpen={openModal} filters={filters} onClose={() => setOpenModal(false)} title={t('map.filtersModal.title')} serviceTypeLabel={t('map.filtersModal.serviceTypeLabel')} serviceType={{
        buy: t('map.filtersModal.serviceType.buy'),
        buyAndSell: t('map.filtersModal.serviceType.buyAndSell')
      }} openNowLabel={t('map.filtersModal.openNowLabel')} confirmButtonLabel={t('common.apply')} clearFiltersButtonLabel={t('common.clearAll')} onApply={handleApplyFilters} data-sentry-element="SearchModal" data-sentry-source-file="Map.tsx" />
        {!noList && <div className="flex w-full lg:hidden">
            <Tab onClick={() => setIsMapActive(false)} active={!isMapActive}>
              {t('map.listTabLabel')}
            </Tab>
            <Tab onClick={() => setIsMapActive(true)} active={isMapActive}>
              {t('map.mapTabLabel')}
            </Tab>
          </div>}
        {!noList && <div className={clsx('flex flex-1 flex-col bg-white lg:bg-inherit', {
        'hidden lg:flex': isMapActive
      })}>
            <div className="px-6 pb-4 pt-4 lg:px-0 lg:pb-8 lg:pt-0">
              {t('map.resultsLabel', {
            numberOfLocations: listLocations.length
          })}
            </div>
            <div className="h-[910px] lg:h-[540px]">
              {listLocations.length || isLoading ? <LocationList onChangeLocation={onChangeLocation} locations={listLocations} isLoading={isLoading} /> : <div className="animate-fade-in">
                  {lang === LOCALES.EN_CA && <div className="mb-4 rounded-md border border-gray-200 px-5 py-8">
                      <h5 className="mb-2 text-center text-body-5 font-semibold">
                        {t('map.noResults.sellCryptoTitle')}
                      </h5>
                      <p className="mb-4 text-center text-body-8 text-gray-500">
                        {t('map.noResults.sellCryptoSubtitle')}
                      </p>
                      <LinkButton href={ROUTES.InteracLink}>{t('common.learnMoreText')}</LinkButton>
                    </div>}
                  <div className="rounded-md border border-gray-200 px-5 py-8">
                    <h5 className="mb-2 text-center text-body-5 font-semibold">
                      {t('map.noResults.title')}
                    </h5>
                    <p className="mb-4 text-center text-body-8 text-gray-500">
                      {t('map.noResults.subtitle')}
                    </p>
                    <LinkButton href="/contact">{t('common.contactUsText')}</LinkButton>
                  </div>
                </div>}
            </div>
          </div>}
      </div>
      {isMapLoaded ? <GoogleMap onLoad={setMap} onUnmount={() => setMap(undefined)} mapContainerClassName={clsx('w-full lg:rounded-[.625rem]', isDesktop && !noList ? 'h-auto' : 'lg:h-[480px] h-[386px]', isMapActive || isDesktop ? 'visible relative' : 'invisible !absolute', mapContainerClassName)} center={initialMapCenter} zoom={initialZoom} onBoundsChanged={updateMapBounds} onZoomChanged={updateMapBounds} onDragEnd={updateMapBounds} clickableIcons={false} options={{
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      styles: mapStyles,
      fullscreenControl: isMobile ? false : true
    }}>
          <MapLegend map={map} />
          <MarkerClusterer styles={clusterStyles} averageCenter={true}>
            {clusterer => <>
                {allFilteredLocations.map(item => {
            const availableCoins = getAvailableCoins(item, coins);
            return <MapMarker key={item.id} isBuyAndSell={!!item.isTwoWay || !!item.is_two_way} isMobile={isMobile || false} availableCoins={availableCoins} onClose={onMarkerClose} onClick={event => onMarkerClick(event, item)} openedTooltipId={tooltipLocationId} item={item} clusterer={clusterer} />;
          })}
              </>}
          </MarkerClusterer>
          {centerPosition && <Marker position={centerPosition} />}
        </GoogleMap> : <div className="skeleton lg:rounded-[.625rem]" />}
    </div>;
};
export default Map;