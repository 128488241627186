'use client';

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import SearchIcon from '@/public/assets/svg/search.svg';
import { checkIfIOS, disableIosTextFieldZoom } from '@/utils/safari';
import useSearchAutocomplete from '@/hooks/useSearchAutocomplete';
import { Coords } from '@/types/location';
import { ComboboxProps, Option } from '@/components/organisms/Hero/partials/LocationSelect/types';
import SearchInput from '../SearchInput';
type LocationSelectProps = {
  className?: string;
  placeholder?: string;
  onSelect?: (cords: Coords) => void;
  defaultValue?: string | null;
  isGoogleMapsScriptLoaded: boolean;
} & Required<Pick<ComboboxProps, 'loadingText' | 'noOptionsMessage'>>;
const LocationInput = ({
  className,
  placeholder,
  onSelect,
  defaultValue,
  isGoogleMapsScriptLoaded,
  loadingText,
  noOptionsMessage
}: LocationSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const initCalledRef = useRef(false);
  const {
    options,
    isLoading,
    clearSuggestions,
    setGooglePlacesValue,
    init,
    ready
  } = useSearchAutocomplete({
    initOnMount: false
  });
  useEffect(() => {
    if (!isGoogleMapsScriptLoaded || initCalledRef.current) {
      return;
    }
    initCalledRef.current = true;
    init();
  }, [init, isGoogleMapsScriptLoaded]);
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });
  const handleChange = async (option: Option | null) => {
    if (!option) {
      return;
    }
    setSelectedOption(option);
    const geocode = await getGeocode({
      placeId: option.value
    });
    const {
      lat,
      lng
    } = getLatLng(geocode[0]);
    onSelect?.({
      latitude: lat,
      longitude: lng
    });
  };
  useEffect(() => {
    if (checkIfIOS()) {
      disableIosTextFieldZoom();
    }
  }, []);
  useEffect(() => {
    if (isLoading || !ready || selectedOption) {
      return;
    }
    const asyncFn = async () => {
      if (!defaultValue) {
        return;
      }
      const geocode = await getGeocode({
        placeId: defaultValue
      });
      const formattedAddress = geocode[0].formatted_address;
      const latitude = geocode[0].geometry.location.lat();
      const longitude = geocode[0].geometry.location.lng();
      setSelectedOption({
        label: formattedAddress,
        value: defaultValue
      });
      onSelect?.({
        latitude,
        longitude
      });
    };
    asyncFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, ready]);
  return <div className={clsx('relative', className)} {...{
    ref
  }} data-sentry-component="LocationInput" data-sentry-source-file="index.tsx">
      <SearchInput options={options} onInputChange={setGooglePlacesValue} value={selectedOption} onChange={handleChange} LeftIcon={SearchIcon} placeholder={placeholder} isLoading={isLoading || !isGoogleMapsScriptLoaded} noOptionsMessage={noOptionsMessage} loadingText={loadingText} data-sentry-element="SearchInput" data-sentry-source-file="index.tsx" />
    </div>;
};
export default LocationInput;