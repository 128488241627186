'use client';

import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Tag from '@/components/atoms/Tag';
import { parseLocationDetails } from '@/utils';
import { useLang } from '@/hooks/useLang';
import OpenClosedSkeleton from './partials/OpenClosedSkeleton';
import { OpenClosedProps } from './types';
import { formatTime } from './utils';
const OpenClosed = ({
  locationDetails,
  reverse
}: OpenClosedProps) => {
  const lang = useLang();
  const [clientRendered, setClientRendered] = useState(false);
  const {
    t
  } = useTranslation();
  const {
    openNow,
    openTime,
    closeHour
  } = parseLocationDetails(locationDetails);
  const is24Hours = openTime === '24/7' || openTime === t('openingHours.24h');
  const openText = useMemo(() => {
    if (is24Hours) {
      return openTime;
    }
    return t('openingHours.openUntilLabel', {
      closingHour: formatTime(closeHour, lang)
    });
  }, [is24Hours, t, closeHour, lang, openTime]);
  useEffect(() => {
    setClientRendered(true);
  }, []);
  if (!clientRendered) {
    return <OpenClosedSkeleton />;
  }
  return <div className={clsx('flex items-center gap-x-2', reverse && 'flex-row-reverse')} data-sentry-component="OpenClosed" data-sentry-source-file="index.tsx">
      {openNow === null ? <>
          <Tag className="min-w-max" variant="red">
            {t('openingHours.temporaryClosed')}
          </Tag>
        </> : openNow ? <>
          <Tag className="min-w-max" variant="green">
            {t('openingHours.open')}
          </Tag>
          • <span>{openText}</span>
        </> : <>
          <Tag className="min-w-max" variant="red">
            {t('openingHours.closed')}
          </Tag>
          •{' '}
          <span>
            {t('openingHours.opensAtLabel', {
          openingHour: formatTime(openTime, lang)
        })}
          </span>
        </>}
    </div>;
};
export default OpenClosed;