import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapLegendProps } from './types';
const MapLegend = ({
  map
}: MapLegendProps) => {
  const {
    t
  } = useTranslation();
  const [isStreetViewActive, setIsStreetViewActive] = useState(false);
  useEffect(() => {
    if (map) {
      const streetViewPanorama = map.getStreetView();
      const streetViewVisibleListener = streetViewPanorama.addListener('visible_changed', () => {
        const streetViewVisible = streetViewPanorama.getVisible();
        setIsStreetViewActive(streetViewVisible);
      });
      return () => {
        google.maps.event.removeListener(streetViewVisibleListener);
      };
    }
  }, [map]);
  if (isStreetViewActive) {
    return null;
  }
  return <div className="absolute left-6 top-4 z-30 flex items-center gap-x-2 rounded-md border border-gray-200 bg-white/60 px-4 py-2.5 text-body-9 shadow-elevation-4 lg:left-4 lg:text-body-8" data-sentry-component="MapLegend" data-sentry-source-file="index.tsx">
      <span className="h-3 w-3 rounded-full bg-green-600 lg:h-4 lg:w-4" />
      <span>{t('map.legend.buy')}</span>
      <span className="w-px text-gray-300">|</span>
      <span className="h-3 w-3 rounded-full bg-primary-600 lg:h-4 lg:w-4" />
      <span>{t('map.legend.buyAndSell')}</span>
    </div>;
};
export default MapLegend;