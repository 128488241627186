'use client';

import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { Content } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { Simplify } from '@/prismicio-types';
import RichText from '@/components/atoms/RichText';
type KeyValue = {
  [key: string]: Simplify<Content.TabsSectionSliceDefaultItem>[];
};
type Props = {
  items: Simplify<Content.TabsSectionSliceDefaultItem>[];
  className?: string;
};
const TabsComponent = ({
  items,
  className
}: Props) => {
  const [activeTab, setActiveTab] = useState(items[0]?.tab_title);
  const tabs: KeyValue = useMemo(() => {
    const hashmap: KeyValue = {};
    items.forEach(item => {
      if (!hashmap[item.tab_title as string]) {
        hashmap[item.tab_title as string] = [];
      }
      hashmap[item.tab_title as string].push(item);
    });
    return hashmap;
  }, [items]);
  return <div className={className} data-sentry-component="TabsComponent" data-sentry-source-file="index.tsx">
      <div className="grid h-12 w-full grid-flow-col gap-x-3">
        {Object.entries(tabs).map(([key]) => {
        return <div role="button" onClick={() => setActiveTab(key)} className={clsx('flex items-center justify-center border-b-[3px] text-center', key == activeTab ? 'border-b-primary-600 font-bold' : 'border-b-gray-200 font-semibold text-gray-400')} key={key}>
              {key}
            </div>;
      })}
      </div>
      {activeTab && <div className="grid w-full grid-cols-1 gap-y-6 py-8 lg:grid-cols-2 lg:px-2.5">
          {tabs[activeTab].map(item => {
        return <div className="flex" key={item.tab_item_title}>
                <PrismicNextImage className="mr-4 h-16 w-16" field={item.tab_item_image} />
                <div>
                  <div className="text-h7 font-bold">{item.tab_item_title}</div>
                  <div className="guides-wysiwyg">
                    <RichText field={item.tab_item_description} />
                  </div>
                </div>
              </div>;
      })}
        </div>}
    </div>;
};
export default TabsComponent;