import React from 'react';
import { Marker, MarkerProps } from '@react-google-maps/api';
import { Location } from '@/utils/types';
import { CoinsDocumentDataCoinsItem, Simplify } from '@/prismicio-types';
import LocationTooltip from '../LocationTooltip';
interface Props extends Omit<MarkerProps, 'position'> {
  item: Location;
  onClick: (e: google.maps.MapMouseEvent) => void;
  onClose: () => void;
  openedTooltipId: string;
  isBuyAndSell: boolean;
  isMobile: boolean;
  availableCoins: {
    buy: Simplify<CoinsDocumentDataCoinsItem>[];
    sell: Simplify<CoinsDocumentDataCoinsItem>[];
  };
}
const MapMarker = ({
  item,
  onClick,
  openedTooltipId,
  onClose,
  isMobile,
  isBuyAndSell,
  availableCoins,
  ...rest
}: Props) => {
  const position = {
    lat: +item.location.latitude,
    lng: +item.location.longitude
  };
  return <Marker onClick={onClick} icon={{
    url: isBuyAndSell ? '/assets/svg/circle-buy-and-sell.svg' : '/assets/svg/circle-buy.svg',
    scaledSize: new google.maps.Size(15, 15)
  }} key={item.id} position={position} {...rest} data-sentry-element="Marker" data-sentry-component="MapMarker" data-sentry-source-file="index.tsx">
      {openedTooltipId === item.id && <LocationTooltip isBuyAndSell={isBuyAndSell} onClose={onClose} location={item} availableCoins={availableCoins} openedTooltipId={openedTooltipId} position={position} isMobile={isMobile} />}
    </Marker>;
};
export default MapMarker;