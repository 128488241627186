import useSWR from 'swr';

import { getCryptos } from '@/utils';
import { ROUTES } from '@/routes';
import { CryptoResponse } from '@/components/molecules/CryptoCarousel/types';

import { useLang } from '../useLang';

import { UseGetCryptosProps, CryptoChangeData } from './types';
import { useCryptoChanges } from './hooks';

export const useCryptos = ({ coins, filter }: UseGetCryptosProps) => {
  const lang = useLang();

  const { data: cryptoChanges, isLoading: isCryptoChangesLoading } = useCryptoChanges(coins || []);

  const cryptoChangesMap = cryptoChanges?.reduce<Record<string, CryptoChangeData>>((acc, curr) => {
    acc[curr.data.symbol.toLowerCase()] = curr.data;

    return acc;
  }, {});

  const { data: cryptoResponse } = useSWR<CryptoResponse>(
    [ROUTES.APICryptos, lang, filter],
    async () => {
      const response = await getCryptos({
        coins: coins || [],
        filter,
        locale: lang,
        cryptoChanges: cryptoChangesMap,
      });

      return response;
    },
    {
      refreshInterval: 10000, // 10 seconds
      revalidateOnFocus: true,
      isPaused: () => isCryptoChangesLoading,
      fallbackData: { result: null, isLoading: true },
    },
  );

  return {
    data: cryptoResponse,
    isLoading: cryptoResponse?.isLoading,
  };
};
