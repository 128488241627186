'use client';

import { format } from 'url';
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useRouter } from 'next/navigation';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import useUserLocation from '@/hooks/useUserLocation';
import { ArrowRightIcon, MapPinIcon } from '@/public/assets/svg';
import { checkIfIOS, disableIosTextFieldZoom } from '@/utils/safari';
import { useLoadGoogleMapsScript } from '@/hooks/useLoadGoogleMapsScript';
import { useLang } from '@/hooks/useLang';
import { ROUTES } from '@/routes';
import { BaseAutocompleteItem } from '@/components/atoms/Autocomplete/types';
import Spinner from '@/components/atoms/Spinner';
import { addLocale } from '@/utils/locales';
import { pushGTMEvent } from '@/utils/gtm';
import { HOME_PAGE_ADDRESS_ENTERED_EVENT } from '@/utils/gtm/consts';
import { LocationSelectProps, LocationSelectOption, PlacesOption, DefaultLocationOption } from './types';
import { getDefaultLocationsByLocale } from './utils';
const LocationSelect = ({
  placeholder,
  className
}: LocationSelectProps) => {
  const router = useRouter();
  const lang = useLang();
  const initRef = useRef(false);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState<LocationSelectOption | null>(null);
  const [currentLocation, setCurrentLocation] = useState<LocationSelectOption | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: userLocation
  } = useUserLocation();
  const {
    isLoaded
  } = useLoadGoogleMapsScript();
  const {
    ready,
    setValue: setGooglePlacesValue,
    suggestions: {
      data,
      status: googlePlacesStatus
    },
    clearSuggestions,
    init,
    value
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: false
  });
  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  const handleSubmit = async () => {
    if (!selectedOption) return;
    let selectedLatitude: number, selectedLongitude: number;
    try {
      setIsLoading(true);
      if (selectedOption.type === 'defaultLocation') {
        const [latitude, longitude] = selectedOption.value.split(',').map(Number);
        selectedLatitude = latitude;
        selectedLongitude = longitude;
      } else {
        const placeId = selectedOption.value;
        const geocode = await getGeocode({
          placeId
        });
        const {
          lat: latitude,
          lng: longitude
        } = getLatLng(geocode[0]);
        selectedLatitude = latitude;
        selectedLongitude = longitude;
      }
      const url = format({
        pathname: addLocale(ROUTES.bitcoinATM(lang), lang),
        query: {
          latitude: selectedLatitude,
          longitude: selectedLongitude,
          placeId: selectedOption.value
        }
      });
      pushGTMEvent({
        event: HOME_PAGE_ADDRESS_ENTERED_EVENT
      }, () => {
        router.push(url);
      });
    } catch {
      setIsLoading(false);
    }
  };
  const options: LocationSelectOption[] = useMemo(() => {
    if (!ready) {
      return [];
    }
    if (googlePlacesStatus === 'ZERO_RESULTS') {
      return [{
        label: 'No results',
        value: '',
        type: 'defaultLocation'
      } as DefaultLocationOption];
    }
    if (data.length === 0 && currentLocation && !value) {
      if (userLocation?.isFromBrowser) {
        return [currentLocation];
      }
      const defaultLocations = getDefaultLocationsByLocale(lang);
      return defaultLocations.map<DefaultLocationOption>(city => ({
        label: city.city,
        value: `${city.latitude},${city.longitude}`,
        type: 'defaultLocation'
      }));
    }
    return data.map<PlacesOption>(suggestion => {
      const {
        place_id,
        structured_formatting: {
          main_text,
          secondary_text
        }
      } = suggestion;
      return {
        label: [main_text, secondary_text].filter(Boolean).join(' ') || 'Missing label',
        value: place_id,
        type: 'places'
      };
    });
  }, [ready, data, value, googlePlacesStatus, currentLocation, userLocation?.isFromBrowser, lang]);
  useEffect(() => {
    if (!ready || !userLocation) {
      if (!userLocation) {
        setCurrentLocation(null);
      }
      return;
    }
    const setUserLocationAsOption = async () => {
      try {
        const {
          latitude,
          longitude
        } = userLocation?.location || {};
        const geocode = await getGeocode({
          location: {
            lat: latitude,
            lng: longitude
          }
        });
        const {
          place_id,
          formatted_address
        } = geocode[0];
        setCurrentLocation({
          label: formatted_address,
          value: place_id,
          type: 'places'
        });
      } catch {
        setCurrentLocation(null);
      }
    };
    setUserLocationAsOption();
  }, [ready, userLocation]);
  useEffect(() => {
    if (checkIfIOS()) {
      disableIosTextFieldZoom();
    }
  }, []);
  useEffect(() => {
    if (isLoaded && !initRef.current) {
      init();
      initRef.current = true;
    }
  }, [isLoaded, init]);
  return <div className={clsx('relative', className)} ref={ref} data-sentry-component="LocationSelect" data-sentry-source-file="index.tsx">
      <Combobox<LocationSelectOption | null> value={selectedOption} onChange={option => {
      setSelectedOption(option);
      clearSuggestions();
    }} virtual={{
      options
    }} onClose={() => setIsFocused(false)} immediate data-sentry-element="Combobox" data-sentry-source-file="index.tsx">
        <div className="relative">
          <div className={clsx('relative w-full cursor-default overflow-hidden rounded-md border border-gray-200 bg-white text-left hover:border-gray-300', {
          'rounded-b-none': isFocused
        })}>
            <div className="absolute bottom-0 left-3.5 top-0 flex items-center justify-center">
              <MapPinIcon width={16} height={16} data-sentry-element="MapPinIcon" data-sentry-source-file="index.tsx" />
            </div>
            <ComboboxInput className={clsx('w-full border-none py-3.5 pl-10 pr-12 text-button text-gray-600 caret-primary-600 shadow-none focus:outline-none')} placeholder={placeholder} onChange={event => {
            const newValue = event.target.value;
            setGooglePlacesValue(newValue);
          }} displayValue={() => selectedOption?.label || ''} onFocus={() => {
            setIsFocused(true);
            setGooglePlacesValue('');
            setSelectedOption(null);
          }} onBlur={() => {
            setIsFocused(false);
          }} data-sentry-element="ComboboxInput" data-sentry-source-file="index.tsx" />
          </div>
          <div className="absolute bottom-0 right-1.5 top-0 flex items-center justify-center">
            {isLoading ? <div className="flex h-9 w-9 items-center justify-center transition-all duration-300">
                <Spinner className="h-5 w-5" />
              </div> : <button name="select-submit" disabled={!selectedOption || isLoading} onClick={handleSubmit} className={clsx('flex h-9 w-9 items-center justify-center rounded-full transition-all duration-300', {
            'bg-primary-600': selectedOption,
            'bg-gray-200': !selectedOption
          })} aria-label="select-submit">
                <ArrowRightIcon width={24} height={24} />
              </button>}
          </div>
          <ComboboxOptions className="absolute z-50 max-h-60 w-full overflow-auto rounded-md rounded-t-none border border-t-0 border-gray-200 bg-white" modal={false} data-sentry-element="ComboboxOptions" data-sentry-source-file="index.tsx">
            {({
            option
          }) => <ComboboxOption value={option} className={clsx('relative w-full select-none px-3 py-3.5 text-button text-gray-600 data-[focus]:bg-gray-50', {
            'cursor-pointer hover:bg-gray-50': googlePlacesStatus !== 'ZERO_RESULTS'
          })} disabled={googlePlacesStatus === 'ZERO_RESULTS'}>
                {(option as BaseAutocompleteItem).label}
              </ComboboxOption>}
          </ComboboxOptions>
        </div>
      </Combobox>
    </div>;
};
export default LocationSelect;