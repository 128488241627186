import React, { ComponentProps } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { renderToString } from 'react-dom/server';
import { KeyTextField } from '@prismicio/client';
import { InfoIcon } from '@/public/assets/svg';
import TooltipContent from '@/components/atoms/TooltipContent';
const OPEN_EVENTS: ComponentProps<typeof ReactTooltip>['openEvents'] = {
  click: true,
  focus: true,
  mouseenter: true,
  mousedown: true,
  mouseover: true
};
const Tooltip: React.FC<{
  content: KeyTextField | undefined;
}> = ({
  content
}) => <>
    <ReactTooltip id="tooltip-icon" style={{
    backgroundColor: 'white',
    borderRadius: '.375rem'
  }} openEvents={OPEN_EVENTS} border="1px solid var(--gray-200)" data-sentry-element="ReactTooltip" data-sentry-source-file="index.tsx" />
    <InfoIcon className="h-5 w-5 cursor-pointer text-primary-600 hover:opacity-50" data-tooltip-id="tooltip-icon" data-tooltip-html={renderToString(<TooltipContent description={content || ''} />)} data-sentry-element="InfoIcon" data-sentry-source-file="index.tsx" />
  </>;
export default Tooltip;