import { Content, GroupField } from '@prismicio/client';
import useSWR from 'swr';

import { Simplify } from '@/prismicio-types';
import { ROUTES } from '@/routes';

import { CRYPTO_CHANGES_KEY } from './consts';
import { CryptoChangeResponse } from './types';

export const useCryptoChanges = (
  coins: GroupField<Simplify<Content.CoinsDocumentDataCoinsItem>>,
) => {
  const coinNames = coins.map((coin) => coin.name || '').filter(Boolean);
  const shouldFetch = coinNames.length > 0;

  return useSWR<CryptoChangeResponse[]>(
    shouldFetch ? [CRYPTO_CHANGES_KEY, ...coinNames] : null,
    async () => {
      const coinNamesString = coinNames.join(',');
      const url = ROUTES.APICryptoChanges(coinNamesString);
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Failed to fetch crypto changes');
      }

      return response.json();
    },
    {
      refreshInterval: 60 * 60 * 1000, // 1 hour
      revalidateOnMount: true,
    },
  );
};
