'use client';

import { ComponentProps, useState } from 'react';
import LinkButton from '@/components/atoms/LinkButton';
import LocationInput from '@/components/molecules/LocationInput';
import { useLang } from '@/hooks/useLang';
import { useLoadGoogleMapsScript } from '@/hooks/useLoadGoogleMapsScript';
import { ROUTES } from '@/routes';
import { Coords } from '@/types/location';
export interface BannerLocationInputProps extends Pick<ComponentProps<typeof LocationInput>, 'loadingText' | 'noOptionsMessage'> {
  title: string;
  inputPlaceholder: string;
  buttonText: string;
}
const BannerLocationInput = ({
  title,
  inputPlaceholder,
  buttonText,
  loadingText,
  noOptionsMessage
}: BannerLocationInputProps): JSX.Element => {
  const lang = useLang();
  const [location, setLocation] = useState<Coords>({
    latitude: null,
    longitude: null
  });
  const {
    isLoaded
  } = useLoadGoogleMapsScript();
  return <div className="z-10 flex flex-col" data-sentry-component="BannerLocationInput" data-sentry-source-file="BannerLocationInput.tsx">
      <h2 className="mb-6 w-full text-h3 font-bold text-gray-600 md:w-[440px] md:text-title">
        {title}
      </h2>
      <div className="w-dull flex flex-col md:w-[31.875rem] md:flex-row">
        <LocationInput className="mb-6 h-11 w-full md:mb-0" onSelect={setLocation} placeholder={inputPlaceholder} isGoogleMapsScriptLoaded={isLoaded} loadingText={loadingText} noOptionsMessage={noOptionsMessage} data-sentry-element="LocationInput" data-sentry-source-file="BannerLocationInput.tsx" />
        <LinkButton href={{
        pathname: ROUTES.bitcoinATM(lang),
        query: {
          latitude: location.latitude,
          longitude: location.longitude
        }
      }} className="w-full md:ml-4 md:w-[10.375rem] md:flex-shrink-0" disabled={!location.latitude || !location.longitude} data-sentry-element="LinkButton" data-sentry-source-file="BannerLocationInput.tsx">
          {buttonText}
        </LinkButton>
      </div>
    </div>;
};
export default BannerLocationInput;