'use client';

import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LOCALES } from '@/i18nConfig';
import Link from '@/components/atoms/Link';
import { ChevronDownIcon } from '@/public/assets/svg';
import { getCountryFromLocale } from '@/app/api/utils';
import { Lang } from '@/types/locales';
import { BreadCrumbProps } from './types';
const Breadcrumbs = ({
  homeBreadcrumbLabel,
  separator,
  containerClasses,
  listClasses,
  activeClasses,
  start = 0,
  lang
}: BreadCrumbProps) => {
  const {
    t
  } = useTranslation();
  const paths = usePathname().replace(new RegExp(`(${Object.values(LOCALES).join('|')})`, 'i'), '');
  const strippedPath = Object.values(LOCALES).reduce((acc, locale) => {
    if (acc.startsWith(`/${locale}`)) {
      return acc.replace(`/${locale}`, '');
    }
    return acc;
  }, paths);
  const pathNames = strippedPath.split('/').filter(path => path).slice(start);
  const resolvedHomeBreadcrumbLabel = homeBreadcrumbLabel || t('breadcrumbs.homepage');
  return <div data-sentry-component="Breadcrumbs" data-sentry-source-file="index.tsx">
      <ul className={containerClasses}>
        <div className="hidden items-center lg:flex">
          {!!resolvedHomeBreadcrumbLabel && <li className={listClasses}>
              <Link className="uppercase" href="/">
                {resolvedHomeBreadcrumbLabel}
              </Link>
            </li>}
          {pathNames.length > 0 && !!resolvedHomeBreadcrumbLabel && separator}
        </div>
        {pathNames.map((link, index) => {
        const href = `/${pathNames.slice(0, index + 1).join('/')}`;
        const itemClasses = strippedPath.replace(/\//g, '') === href.replace(/\//g, '') ? `${listClasses} ${activeClasses}` : listClasses;
        let itemLink = link.replace(/[^A-Z0-9]/gi, ' ');
        itemLink = itemLink === 'guide' ? 'guides' : itemLink;
        itemLink = itemLink === 'blog' ? 'blogs' : itemLink;
        if (itemLink === 'bitcoin atm') {
          // Hong Kong locale uses /bitcoin-atm/hong-kong/ for locations homepage
          if (lang === LOCALES.EN_HK || lang === LOCALES.ZH_HK) {
            return null;
          }
          const countryName = getCountryFromLocale((lang || LOCALES.EN_CA) as Lang);
          itemLink = countryName;
        }
        return <li key={index} className={clsx('items-center', {
          'hidden lg:flex': pathNames.length >= 4,
          flex: pathNames.length < 4
        })}>
              <p className={itemClasses}>
                <Link className="uppercase" href={href}>
                  {itemLink}
                </Link>
              </p>
              {pathNames.length !== index + 1 && separator}
            </li>;
      })}
        <li>
          <Link className={clsx('flex items-center gap-x-2.5 text-body-8 font-semibold lg:hidden', {
          hidden: pathNames.length < 4
        })} href={`/${pathNames.slice(0, -1).join('/')}`} data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <ChevronDownIcon className="h-4 w-4 rotate-90" data-sentry-element="ChevronDownIcon" data-sentry-source-file="index.tsx" />
            <span className="uppercase">
              {t('breadcrumbs.backToPreviousBreadcrumbLabel', {
              previousBreadcrumbLabel: pathNames.length > 1 ? pathNames[pathNames.length - 2] : pathNames[0]
            })}
            </span>
          </Link>
        </li>
      </ul>
    </div>;
};
export default Breadcrumbs;