'use client';

import LinkButton from '@/components/atoms/LinkButton';
import { LinkButtonProps } from '@/components/atoms/LinkButton/types';
import { pushGTMEvent } from '@/utils/gtm';
const HeroLinkButton = ({
  children,
  eventName,
  ...props
}: LinkButtonProps) => {
  return <LinkButton onClick={() => {
    if (eventName) {
      pushGTMEvent({
        event: eventName
      });
    }
  }} {...props} data-sentry-element="LinkButton" data-sentry-component="HeroLinkButton" data-sentry-source-file="index.tsx">
      {children}
    </LinkButton>;
};
export default HeroLinkButton;