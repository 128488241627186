'use client';

import React from 'react';
import clsx from 'clsx';
import { AddIcon, ArrowSwapHorizontalIcon, BtcIcon, DollarBillIcon, MinusIcon } from '@/public/assets/svg';
import { MONEY_STEP } from '../../organisms/CalculatorWithButton/consts';
import { useETransferCryptoAmount } from './hooks';
import { CalculatorDesktopProps } from './types';
import { clampValue } from './utils';
const CalculatorDesktop = ({
  className,
  amount = 100,
  setAmount,
  amountCurrency,
  fiatInputLabel,
  cryptoInputLabel,
  ...props
}: CalculatorDesktopProps) => {
  const btcAmount = useETransferCryptoAmount(amount || 0);
  return <div className={clsx('w-full bg-white', className)} {...props} data-sentry-component="CalculatorDesktop" data-sentry-source-file="index.tsx">
      <div className="mb-[1.875rem] flex h-[6.625rem] flex-row">
        <div className={clsx('relative inline-block h-full w-full rounded-[.625rem] border border-gray-200 p-5 pr-[3.125rem]', `after:absolute after:border after:border-gray-200 after:bg-white after:content-['']`, 'after:bottom-0 after:right-[-11px] after:top-0 after:mb-auto after:mt-auto', 'after:h-[4.25rem] after:w-9', 'after:rounded-l-full after:rounded-tr-none after:border-r-white')}>
          <p className="mb-3 text-body-8 text-gray-500">{fiatInputLabel}</p>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
              <button type="button" aria-label="decrease amount" onClick={() => setAmount(currentValue => clampValue(currentValue - MONEY_STEP))}>
                <MinusIcon className="h-7 w-7 text-gray-600" data-sentry-element="MinusIcon" data-sentry-source-file="index.tsx" />
              </button>
              <input className="w-28 px-2 text-center text-h6 font-bold" type="number" value={amount || ''} onChange={event => setAmount(event.target.valueAsNumber)} onBlur={event => setAmount(clampValue(event.target.valueAsNumber))} />
              <button type="button" aria-label="increase amount" onClick={() => setAmount(currentValue => clampValue(currentValue + MONEY_STEP))}>
                <AddIcon className="h-7 w-7 text-gray-600" data-sentry-element="AddIcon" data-sentry-source-file="index.tsx" />
              </button>
            </div>
            <div className="flex flex-row items-center gap-3">
              <div className="flex items-center justify-center rounded-full bg-gray-100 p-2">
                <DollarBillIcon className="h-6 w-6" data-sentry-element="DollarBillIcon" data-sentry-source-file="index.tsx" />
              </div>
              <span className="text-body-5 font-semibold text-gray-600">{amountCurrency}</span>
            </div>
          </div>
        </div>
        <div className="relative z-30 flex h-full w-5 flex-shrink-0 items-center justify-center bg-white">
          <ArrowSwapHorizontalIcon className="absolute bottom-0 top-0 mb-auto mt-auto h-7 w-7 text-gray-500" data-sentry-element="ArrowSwapHorizontalIcon" data-sentry-source-file="index.tsx" />
        </div>
        <div className={clsx('relative h-full w-full rounded-[.625rem] border border-gray-200 bg-gray-200 p-5 pl-[3.125rem]', `before:absolute before:border before:border-gray-200 before:bg-white before:content-['']`, 'before:bottom-0 before:left-[-11px] before:top-0 before:mb-auto before:mt-auto before:h-[4.25rem] before:w-9', 'before:rounded-r-full before:rounded-bl-none before:border-l-white')}>
          <p className="mb-3 text-body-8 text-gray-500">{cryptoInputLabel}</p>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
              <span className="text-center text-h6 font-bold text-gray-500">{btcAmount}</span>
            </div>
            <div className="flex flex-row items-center gap-3">
              <div className="flex items-center justify-center rounded-full bg-primary-600 p-2">
                <BtcIcon className="h-6 w-6 text-white" data-sentry-element="BtcIcon" data-sentry-source-file="index.tsx" />
              </div>
              <span className="text-body-5 font-semibold text-gray-600">BTC</span>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default CalculatorDesktop;