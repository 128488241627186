import { LOCALES } from '@/i18nConfig';
import { Lang } from '@/types/locales';

export const getDefaultLocationsByLocale = (locale: Lang) => {
  switch (locale) {
    case LOCALES.EN_CA:
    case LOCALES.FR_CA:
      return [
        { city: 'Toronto, ON', latitude: 43.6532, longitude: -79.3832 },
        { city: 'Vancouver, BC', latitude: 49.2827, longitude: -123.1207 },
        { city: 'Montreal, QC', latitude: 45.5017, longitude: -73.5673 },
        { city: 'Calgary, AB', latitude: 51.0447, longitude: -114.0719 },
        { city: 'Ottawa, ON', latitude: 45.4215, longitude: -75.6972 },
      ];
    case LOCALES.EN_AU:
      return [
        { city: 'Sydney, NSW', latitude: -33.8688, longitude: 151.2093 },
        { city: 'Melbourne, VIC', latitude: -37.8136, longitude: 144.9631 },
        { city: 'Brisbane, QLD', latitude: -27.4698, longitude: 153.0251 },
        { city: 'Perth, WA', latitude: -31.9505, longitude: 115.8605 },
        { city: 'Adelaide, SA', latitude: -34.9285, longitude: 138.6007 },
      ];
    case LOCALES.EN_NZ:
      return [
        { city: 'Auckland', latitude: -36.8485, longitude: 174.7633 },
        { city: 'Wellington', latitude: -41.2865, longitude: 174.7762 },
        { city: 'Christchurch', latitude: -43.5321, longitude: 172.6362 },
        { city: 'Hamilton', latitude: -37.787, longitude: 175.2793 },
      ];
    case LOCALES.EN_HK:
    case LOCALES.ZH_HK:
      return [
        { city: 'Central and Western District', latitude: 22.2867, longitude: 114.1545 },
        { city: 'Kowloon', latitude: 22.3167, longitude: 114.1833 },
        { city: 'Wan Chai', latitude: 22.2793, longitude: 114.1717 },
        { city: 'Causeway Bay', latitude: 22.2793, longitude: 114.1833 },
        { city: 'Mong Kok', latitude: 22.3193, longitude: 114.1694 },
      ];
    default:
      return [];
  }
};
