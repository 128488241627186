'use client';

import { Content, GroupField } from '@prismicio/client';
import React from 'react';
import { Simplify } from '@/prismicio-types';
import DesktopTable from '@/components/molecules/DesktopTable';
import MobileCrypto from '@/components/molecules/MobileCrypto';
import { useCryptos } from '@/hooks/UseCryptos';
import { CryptoTableType, HeaderWithTooltip } from '@/utils/types';
import FeesTables from '../FeesTables';
type Props = {
  coins: GroupField<Simplify<Content.CoinsDocumentDataCoinsItem>>;
  variant: 'sellTable' | 'buyTable' | 'sellFeesAndLimits' | 'buyFeesAndLimits';
  headers: HeaderWithTooltip[];
  howToButtonLabelMap: Record<string, string>;
};
const CryptoTableWrapper = ({
  coins,
  headers,
  variant,
  howToButtonLabelMap
}: Props) => {
  const {
    data: rows
  } = useCryptos({
    coins,
    filter: variant == 'sellTable' ? 'sellOnly' : 'all'
  });
  const getHowToButtonLabel: CryptoTableType['howToButtonLabel'] = ticker => howToButtonLabelMap[ticker] || '';
  return <>
      {rows?.error ? <div className="o-container o-container--xl my-8">
          <div className="flex items-center justify-center rounded-lg border border-gray-200 p-8 text-body-7 font-bold">
            {rows?.error}
          </div>
        </div> : variant === 'buyTable' || variant === 'sellTable' ? <>
          <DesktopTable rows={rows?.result?.filter(row => row.sellValue !== '-' || variant !== 'sellTable')} variant={variant} headers={headers} isLoading={rows?.isLoading} howToButtonLabel={getHowToButtonLabel} />
          <MobileCrypto rows={rows?.result?.filter(row => row.sellValue !== '-' || variant !== 'sellTable')} variant={variant} headers={headers} isLoading={rows?.isLoading} howToButtonLabel={getHowToButtonLabel} />
        </> : <FeesTables rows={rows?.result} variant={variant} isLoading={rows?.isLoading} headers={headers} />}
    </>;
};
export default CryptoTableWrapper;