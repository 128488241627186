'use client';

import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import { PrismicNextImage } from '@prismicio/next';
import { useTranslation } from 'react-i18next';
import { StepsSliderProps } from './types';
const StepsSlider = ({
  items,
  className
}: StepsSliderProps) => {
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    align: 'start'
  }, []);
  const {
    t
  } = useTranslation();
  return <div className={clsx('relative w-full', className)} data-sentry-component="StepsSlider" data-sentry-source-file="index.tsx">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex gap-5">
          {items.map(({
          description,
          image
        }, index) => {
          return <div className="relative w-[16.25rem] flex-shrink-0 overflow-visible rounded-[0.625rem] border border-gray-200 bg-white p-6 last:mr-6 last:lg:mr-0" key={description}>
                <PrismicNextImage className="mb-4 h-[9.625rem] w-full rounded-[.625rem] object-cover" field={image} />
                <p className="mb-2 text-caption-3 font-bold uppercase text-green-600">
                  {t('stepByStepGuideSlider.stepLabel', {
                stepNumber: index + 1
              })}
                </p>
                <p className="text-body-5 font-semibold text-gray-600 lg:pr-14">{description}</p>
              </div>;
        })}
        </div>
      </div>
    </div>;
};
export default StepsSlider;