'use client';

import React from 'react';
import Image from 'next/image';
import useSWR from 'swr';
import { useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { findNearestATMs, makeGoogleMapsStaticMapUrl } from '@/utils';
import Link from '@/components/atoms/Link';
import { ArrowRightIcon } from '@/public/assets/svg';
import { ROUTES } from '@/routes';
import { BrowserLocation, Location } from '@/utils/types';
import LinkButton from '@/components/atoms/LinkButton';
import { pushGTMEventForATM } from '@/utils/gtm';
import { ATM_DETAILS_NEAR_ATM_DIRECTIONS_CLICK_EVENT, ATM_DETAILS_VIEW_NEAR_ATM_CLICK_EVENT } from '@/utils/gtm/consts';
import useUserLocation from '@/hooks/useUserLocation';
import { useLang } from '@/hooks/useLang';
import { getLocations } from '@/utils/swrRequests';
import OpenClosed from '../OpenClosed';
import MapListItemSkeleton from './partials/MapListItemSkeleton';
type Props = {
  number?: number;
  region?: string;
  isLoading?: boolean;
  locations?: Location[];
};
const MapList = ({
  locations: locationsProp,
  number = 3,
  region = '',
  isLoading: isLoadingProp
}: Props) => {
  const lang = useLang();
  const {
    data: locationsAPI,
    isLoading: isLoadingAllLocations
  } = useSWR([ROUTES.APILocations, lang], () => getLocations(lang), {
    isPaused: () => {
      return locationsProp !== undefined;
    }
  });
  const locations = locationsProp || locationsAPI?.result;
  const {
    data: userLocation,
    isLoading: isLoadingUserLocation
  } = useUserLocation();
  const {
    isLoaded: isMapLoaded
  } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY as string
  });
  const {
    data: nearest
  } = useSWR(userLocation && locations ? [ROUTES.APINearestATMS(region), lang] : null, async () => findNearestATMs(userLocation?.location as BrowserLocation, locations || [], number));
  const {
    t
  } = useTranslation();
  return <div className="grid w-full grid-cols-1 items-center justify-between gap-x-5 gap-y-8 lg:grid-cols-3" data-sentry-component="MapList" data-sentry-source-file="index.tsx">
      {(isLoadingUserLocation || isLoadingAllLocations || isLoadingProp || !isMapLoaded) && Array.from({
      length: number
    }).map((_, index) => <MapListItemSkeleton key={index} />)}
      {isMapLoaded && nearest?.map(item => {
      const {
        id,
        name,
        description,
        location,
        distance,
        fullAddress
      } = item;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, googleLink = ''] = description.split(';');
      const googleMap = makeGoogleMapsStaticMapUrl(location.latitude, location.longitude, {
        size: '360x240'
      });
      return <div className="mx-auto flex h-full w-full max-w-screen-sm flex-col overflow-hidden rounded-[0.625rem] border border-gray-200 shadow-elevation-4 hover:border-primary-500 lg:max-w-none" key={id}>
              <Link href={googleLink} target="_blank" onClick={() => {
          pushGTMEventForATM(item, ATM_DETAILS_NEAR_ATM_DIRECTIONS_CLICK_EVENT);
        }}>
                <Image width={360} height={240} sizes="(max-width: 1024px) 100vw, 33vw" className="w-full" src={googleMap} alt={''} unoptimized />
              </Link>
              <div className="flex h-full flex-col justify-between px-5 py-6">
                <h4 className="body-5 mb-2 font-bold">{name}</h4>
                <p className="body-7 mb-3 text-gray-500">{fullAddress}</p>
                <div className="mb-5 flex items-center gap-x-2 text-body-8 text-gray-500">
                  <OpenClosed locationDetails={location} />•
                  <span>{t('locationTile.distanceLabel', {
                distance: distance?.toFixed()
              })}</span>
                </div>
                <LinkButton href={ROUTES.location(location)} className="flex h-6 !justify-start !px-0" variant="ghost" onClick={() => pushGTMEventForATM(item, ATM_DETAILS_VIEW_NEAR_ATM_CLICK_EVENT)}>
                  {t('locationTile.viewStoreDetailsButtonLabel')} <ArrowRightIcon />
                </LinkButton>
              </div>
            </div>;
    })}
    </div>;
};
export default MapList;