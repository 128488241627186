'use client';

import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { Tweet } from 'react-tweet';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useMemo } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import XLogo from '@/public/assets/svg/X_logo.svg';
import ArrowRight from '@/public/assets/svg/pager-right.svg';
import ArrowLeft from '@/public/assets/svg/pager-left.svg';
import Button from '@/components/atoms/Button';
import useRWD from '@/hooks/useRWD';

/**
 * Props for `TwitterList`.
 */
export type TwitterListProps = SliceComponentProps<Content.TwitterListSlice>;

/**
 * Component for "TwitterList" Slices.
 */
const TwitterList = ({
  slice
}: TwitterListProps): JSX.Element => {
  const {
    isDesktop
  } = useRWD();
  const additionalFeatures = useMemo(() => {
    if (isDesktop) {
      return [];
    }
    return [Autoplay({
      delay: 4000,
      stopOnInteraction: false
    })];
  }, [isDesktop]);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'start'
  }, additionalFeatures);
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  return <section className="o-container o-container--xl my-44 px-0" data-slice-type={slice.slice_type} data-slice-variation={slice.variation} data-sentry-component="TwitterList" data-sentry-source-file="index.tsx">
      <div className="mb-15 flex w-full justify-center lg:justify-between">
        <div className="hidden w-[6.25rem] lg:block" />
        <h3 className="flex items-center gap-x-2.5 text-h3 font-bold lg:gap-x-4 lg:text-title">
          Featured From <XLogo className="scale-75 lg:scale-100" data-sentry-element="XLogo" data-sentry-source-file="index.tsx" />
        </h3>
        <div className="hidden w-[6.25rem] gap-x-3 lg:flex">
          {slice.items.length > 3 && <>
              <Button onClick={scrollPrev} className="w-11 !p-0" variant="light-outline">
                <ArrowLeft />
              </Button>
              <Button onClick={scrollNext} className="w-11 !p-0" variant="light-outline">
                <ArrowRight />
              </Button>
            </>}
        </div>
      </div>
      <div className="relative">
        <div className="overflow-hidden" ref={emblaRef}>
          <div data-theme="light" className="grid w-full auto-cols-full grid-flow-col md:auto-cols-half lg:auto-cols-third">
            {slice.items.map(item => {
            return <div className="px-2.5" key={item.tweet_id}>
                  <Tweet id={item.tweet_id as string} />
                </div>;
          })}
          </div>
        </div>
      </div>
    </section>;
};
export default TwitterList;